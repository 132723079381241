<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <PartnerToolbar
        v-if="Object.keys(partner).length !== 0"
        :partner="partner"
      />
      <hr>
      <div class="head-card">
        <div class="custom-search flex-wrap">
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t('campaigns.status') }}</label>
            <b-form-select
              v-model="filterStatus"
              :options="[{ value: '', text: this.$t('users.all') }, ...filterOptions]"
            />
          </div>
        </div>
        <hr>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <template
        v-if="!loading & logs.length !== 0"
      >
        <div
          v-for="(log, index) in logsFormat"
          :key="index"
          class="log-row mb-1 mt-1"
          :class="{
            odd: index % 2 !== 0,
            even: index % 2 === 0
          }"
        >
          <p v-if="log.request.body.site.domain">
            <strong>{{ log.request.body.site.domain.replace(/\/$/, '') }}</strong>
          </p>

          <json-viewer
            :expand-depth="2"
            copyable
            boxed
            :value="log"
            @copied="copied()"
          />
          <hr>
        </div>
      </template>
    </b-card>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  VBTooltip,
  BSpinner,
  BFormSelect,
} from 'bootstrap-vue'
import PartnerToolbar from './PartnerToolbar.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    BSpinner,
    BFormSelect,
    PartnerToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: true,
      breadcrumbItems: [],
      partner: {},
      logs: [],
      errors: [],
      filterStatus: '',
      filterOptions: [],
    }
  },
  computed: {
    logsFormat() {
      const logs = this.logs.map(l => {
        const obj = JSON.parse(l)
        const regex = /((\[[^\\}]+)?\{s*[^\\}\\{]{3,}?:.*\}([^\\{]+\])?)/gm
        const json = obj.request.match(regex)
        const requestObj = JSON.parse(json)
        const requestHeaders = obj.request.split('\r\n').slice(0, 6)
        if (!this.filterOptions.includes(obj.status_code)) {
          this.filterOptions.push(obj.status_code)
        }

        if (obj.status_code === 200 && obj.response !== '' && obj.response !== '200 OK') {
          const response = JSON.parse(obj.response)

          return {
            response,
            request: {
              headers: requestHeaders,
              body: requestObj,
            },
            error: obj.error,
            status_code: obj.status_code,
          }
        }
        return {
          response: obj.response,
          request: {
            headers: requestHeaders,
            body: requestObj,
          },
          error: obj.error,
          status_code: obj.status_code,
        }
      })

      if (this.filterStatus) {
        return logs.filter(log => log.status_code === this.filterStatus)
      }

      return logs
    },
  },
  watch: {
    loading(newVal) {
      if (newVal === false) {
        setTimeout(() => {
          const copyButtons = document.querySelectorAll('.jv-tooltip .jv-button')
          if (copyButtons.length) {
            copyButtons.forEach(cb => {
              cb.innerHTML = this.$t('form.copy')
            })
          }
        }, 500)
      }
    },
  },
  async created() {
    this.getPartnerData()
    await this.fetchLogs()
  },
  methods: {
    async fetchLogs() {
      this.logs = await fetch(`https://dsp-eu.phoenix-widget.com/api/v1/requests?c=${this.$route.params.id}`, {
        method: 'get',
        headers: {
          Token: '8AD1dA9j3fpjD(2f1(@jfas2n91dKsd2dfbI',
        },
      })
        .then(response => response.json())
        .then(data => data)
        .catch(error => this.errors.push(error))
      this.loading = false
    },
    async getPartnerData() {
      const responseData = await useJwt.getPartner(this.$route.params.id)
      this.partner = responseData.data.partner || {}
      this.setBreabcrumbs()
    },
    getSiteName(request) {
      const regex = /((\[[^\\}]+)?\{s*[^\\}\\{]{3,}?:.*\}([^\\{]+\])?)/gm
      const json = request.match(regex)
      const requestObj = JSON.parse(json)
      if (requestObj.site.domain !== undefined) {
        return requestObj.site.domain.replace(/\/$/, '')
      }
      return ''
    },
    copied() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('form.copied'),
          icon: 'BellIcon',
        },
      })
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('RTB partners'),
          to: '/partners/',
        },
        {
          text: this.partner.name,
          to: `/partners/${this.$route.params.id}`,
        },
        {
          text: this.$t('logs'),
          active: true,
        },
      ]
    },
  },
}
</script>
